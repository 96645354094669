import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implications for Democracy`}</h1>
    <p>{`In modern democracies, issues are aggregated into party platforms, and opinion polls are restricted to questions to which:`}</p>
    <ul>
      <li parentName="ul">{`Those in power already know the answer to`}</li>
      <li parentName="ul">{`Those in power are ready to hear the answer to`}</li>
      <li parentName="ul">{`Those in power benefit from because they are divisive`}</li>
    </ul>
    <p>{``}<a parentName="p" {...{
        "href": "/computational-sociology",
        "title": "computational sociology"
      }}>{`computational sociology`}</a>{` provides new opportunities to understand populations in their own words on the issues they are interested in discussing, while:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Retaining meaning and coherence lost in comment systems vulnerable to trolling`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoiding the problems of 'comment box' systems where thousands of statements are submitted without any sense of who might be speaking for themselves, or for everyone`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Scale systems and exercises of `}<a parentName="p" {...{
            "href": "/collective-intelligence",
            "title": "collective intelligence"
          }}>{`collective intelligence`}</a>{``}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Move agenda-setting power to the population`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Disaggregate issues, increasing the signal for each`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`See: `}<a parentName="p" {...{
            "href": "/media-coverage",
            "title": "media coverage"
          }}>{`media coverage`}</a>{``}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`See: `}<a parentName="p" {...{
            "href": "/Case-studies",
            "title": "⚗️ Case studies"
          }}>{`⚗️ Case studies`}</a>{``}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`See: `}<a parentName="p" {...{
            "href": "https://civichall.org/civicist/beyond-flatland-machine-learning-end-two-party-binary/"
          }}>{`https://civichall.org/civicist/beyond-flatland-machine-learning-end-two-party-binary/`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      